<template>
    <v-dialog
        v-model="dialog.visible"
        :fullscreen="dialog.fullscreen"
        width="700"
    >
        <template v-slot:activator="{ on }">
            <v-icon
                v-if="isEditing"
                class="mr-2"
                v-on="on"
                @click="updateData"
                :disabled="isDisabled || loading"
            >
                mdi-pencil
            </v-icon>

            <v-btn
                small
                v-else
                color="primary"
                class="mr-3"
                v-on="on"
                @click="preCreationProcess"
                :disabled="isDisabled"
                :loading="loading"
            >
                Add
            </v-btn>
        </template>

        <v-card :loading="loading">
            <v-app-bar
                flat
                color="rgba(0, 0, 0, 0)"
                style="background: rgb(26, 57, 88)"
            >
                <v-toolbar-title class="text-h6 white--text pl-0">
                    {{ isEditing ? "Edit" : "Add" }} Configuration
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                    color="white"
                    icon
                    @click="dialog.fullscreen = !dialog.fullscreen"
                    ><v-icon>mdi-arrow-expand</v-icon></v-btn
                >
                <v-btn color="white" icon @click="dialog.visible = false"
                    ><v-icon>mdi-close</v-icon></v-btn
                >
            </v-app-bar>

            <v-form ref="configForm">
                <v-container style="margin-top: 16px">
                    <v-row v-for="data in filteredHeader" :key="data.text">
                        <v-col cols="4">
                            <label
                                style="
                                    display: flex;
                                    align-items: center;
                                    height: 100%;
                                "
                            >
                                {{ data.text }}
                            </label>
                        </v-col>
                        <v-col cols="8">
                            <v-autocomplete
                                v-if="data.value == 'books'"
                                v-model="tempData[data.value]"
                                :label="
                                    'Please enter a new value for ' + data.text
                                "
                                :items="filteredBookData"
                                item-text="book_name"
                                item-value="book_name"
                                chips
                                clearable
                                small-chips
                                single-line
                            >
                            </v-autocomplete>

                            <v-autocomplete
                                v-else-if="data.value == 'book_filter'"
                                v-model="tempData[data.value]"
                                :label="
                                    'Please enter a new value for ' + data.text
                                "
                                :items="filteredBookData"
                                item-text="book_name"
                                item-value="book_name"
                                chips
                                multiple
                                clearable
                                small-chips
                                single-line
                            >
                            </v-autocomplete>

                            <v-autocomplete
                                v-else-if="data.value == 'broker_id'"
                                v-model="tempData[data.value]"
                                :label="
                                    'Please enter a new value for ' + data.text
                                "
                                :disabled="brokerData.length === 0"
                                :items="brokerData"
                                item-text="broker_name"
                                item-value="id"
                                @change="(e) => onBrokerChange(e)"
                                chips
                                clearable
                                small-chips
                                single-line
                            >
                            </v-autocomplete>

                            <v-autocomplete
                                v-else-if="data.value == 'server_id'"
                                v-model="tempData[data.value]"
                                :label="
                                    selectedBroker === null
                                        ? 'Please select a broker first'
                                        : 'Please enter a new value for ' +
                                          data.text
                                "
                                :disabled="selectedBroker === null"
                                :items="filteredServerOptions"
                                @change="
                                    (e) => {
                                        selectedServer = e;
                                    }
                                "
                                item-text="server_name"
                                item-value="id"
                                chips
                                required
                                small-chips
                                single-line
                            >
                            </v-autocomplete>

                            <v-switch
                                v-else-if="data.value == 'enable_notification'"
                                v-model="tempData[data.value]"
                                hide-details
                                style="margin-top: 13px; margin-bottom: 13px"
                            ></v-switch>
                            <v-row
                                v-else-if="
                                    data.value === 'symbol_regx' ||
                                    data.value === 'group_regx' ||
                                    data.value === 'symbol_group' ||
                                    data.value === 'book_regx' ||
                                    data.value === 'login_list'
                                "
                            >
                                <v-col cols="3">
                                    <v-select
                                        v-model="inputMode[data.value]"
                                        :items="modeOptions"
                                        hide-details
                                        @change="() => onModeChange(data.value)"
                                    ></v-select>
                                </v-col>
                                <v-col cols="9" style="display: flex">
                                    <b
                                        style="
                                            margin-top: 4px;
                                            padding-right: 10px;
                                        "
                                        >{{ inputPrefix[data.value] }}</b
                                    >
                                    <div style="width: -webkit-fill-available">
                                        <v-text-field
                                            v-if="
                                                inputMode[data.value] ===
                                                'regex'
                                            "
                                            v-model="tempData[data.value]"
                                            :rules="inputRules"
                                            required
                                            :placeholder="
                                                'Please enter a new value for ' +
                                                data.text
                                            "
                                        >
                                        </v-text-field>
                                        <v-autocomplete
                                            v-else
                                            :items="filteredOptions[data.value]"
                                            :disabled="
                                                selectedBroker === null ||
                                                selectedServer === null
                                            "
                                            required
                                            item-text="name"
                                            item-value="name"
                                            :placeholder="
                                                selectedBroker === null
                                                    ? 'Please select a broker first'
                                                    : 'Please select a value for ' +
                                                      data.text
                                            "
                                            v-model="tempData[data.value]"
                                        ></v-autocomplete>
                                    </div>
                                    <b
                                        style="
                                            margin-top: 4px;
                                            padding-left: 10px;
                                        "
                                        >{{ inputSuffix[data.value] }}</b
                                    >
                                </v-col>
                            </v-row>
                            <v-autocomplete
                                v-else-if="data.value === 'login_list'"
                                v-model="tempData[data.value]"
                                required
                                hide-details="auto"
                                single-line
                                multiple
                                :placeholder="
                                    'Please select a value for ' + data.text
                                "
                                :items="filteredOptions['login_list']"
                                item-text="name"
                                item-value="name"
                            ></v-autocomplete>

                            <v-text-field
                                v-else-if="
                                    data.value === 'close_profit_threshold'
                                "
                                v-model="tempData[data.value]"
                                :rules="nonZero"
                                required
                                :placeholder="
                                    'Please enter a new value for ' + data.text
                                "
                            ></v-text-field>

                            <v-text-field
                                v-else
                                v-model="tempData[data.value]"
                                :rules="inputRules"
                                :readonly="
                                    data.text == 'Id' ||
                                    data.text == 'Symbol' ||
                                    data.text == 'Server Name'
                                "
                                required
                                :placeholder="
                                    'Please enter a new value for ' + data.text
                                "
                            ></v-text-field
                        ></v-col>
                    </v-row>
                </v-container>
            </v-form>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="onCancel"> Cancel </v-btn>
                <v-btn color="primary" text @click="submit"> Submit </v-btn>
            </v-card-actions>
        </v-card>

        <v-alert type="error" v-if="errorOccur === true">
            Something went wrong, please try again.
        </v-alert>
    </v-dialog>
</template>
<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { snackbar } from "@components/mixins/snackbar";

export default {
    props: ["type", "originalData", "dataHeader", "isEditing", "isDisabled"],
    mixins: [snackbar],
    data() {
        return {
            tempData: {},
            dialog: {
                visible: false,
                fullscreen: false,
            },
            inputRules: [(v) => v === 0 || !!v || "input is required"],
            nonZero: [(v) => v !== 0 || "Value must be non-zero"],
            regexTerms: ["symbol_regx", "group_regx", "symbol_group"],
            inputMode: {
                symbol_regx: "plain",
                group_regx: "plain",
                symbol_group: "plain",
                book_regx: "plain",
                login_list: "plain",
            },
            selectedBroker: null,
            selectedServer: null,
            modeOptions: ["plain", "regex"],
            filteredOptions: {
                symbol_regx: [],
                group_regx: [],
                symbol_group: [],
                book_regx: [],
                login_list: [],
            },
            filteredServerOptions: [],
        };
    },
    watch: {
        updated(nv) {
            if (nv === true) {
                this.dialog.visible = false;
            } else {
                return;
            }
        },
        bookData(nv) {
            this.UPDATE_BOOK_OPTIONS(nv);
        },
        selectedServer(nv) {
            if (nv) {
                const keys = Object.keys(this.tempData).filter(
                    (item) =>
                        Object.keys(this.filteredOptions).includes(item) &&
                        item !== "book_regx" &&
                        item !== "login_list"
                );
                keys.map((key) => {
                    this.filteredOptions[key] = this.options[key].filter(
                        (item) =>
                            item.server_id === nv &&
                            item.name.replaceAll(" ", "") !== ""
                    );
                    this.filteredOptions[key].unshift({
                        name: "*",
                        server_id: nv,
                    });
                });
                const bookKey = Object.keys(this.tempData).filter(
                    (item) => item === "book_regx"
                );
                bookKey.map((key) => {
                    this.filteredOptions[key] = this.options[key].filter(
                        (item) => item.broker_id === this.selectedBroker
                    );
                    this.filteredOptions[key].map((item) => {
                        item.name = item.book_name;
                    });
                    this.filteredOptions[key].unshift({
                        name: "*",
                    });
                });
                const loginKey = Object.keys(this.tempData).filter(
                    (item) => item === "login_list"
                );
                loginKey.map((key) => {
                    this.filteredOptions[key] = this.options[
                        "filter_login"
                    ].filter((item) => item.server_id === this.selectedServer);
                    this.filteredOptions[key].unshift({
                        name: "*",
                    });
                });
            }
        },
    },
    computed: {
        ...mapState("Config", ["updated", "errorOccur", "loading", "options"]),
        ...mapState("SettingsBooks", ["bookData"]),
        ...mapState("SettingsBroker", ["brokerData"]),
        ...mapState("SettingsServer", ["serverData"]),
        filteredHeader() {
            return this.dataHeader.filter((item) => item.value !== "actions");
        },
        filteredBookData() {
            return this.bookData.filter((item) => {
                if (
                    item.book_name != "ALL" &&
                    item.book_name != "UNKNOWN_A" &&
                    item.book_name != "UNKNOWN_B"
                ) {
                    return item;
                }
            });
        },
        inputPrefix() {
            let returnValue = {};
            Object.keys(this.inputMode).map(
                (key) =>
                    (returnValue[key] =
                        this.inputMode[key] === "plain" ? "" : "regex[")
            );
            return returnValue;
        },
        inputSuffix() {
            let returnValue = {};
            Object.keys(this.inputMode).map(
                (key) =>
                    (returnValue[key] =
                        this.inputMode[key] === "plain" ? "" : "]")
            );
            return returnValue;
        },
    },
    methods: {
        ...mapActions("Config", [
            "updateConfigDataAction",
            "createConfigDataAction",
        ]),
        ...mapMutations("Config", [
            "CHANGE_UPDATE_STATUS",
            "CHANGE_ERROR_STATUS",
            "UPDATE_LOADING",
            "UPDATE_BOOK_OPTIONS",
        ]),
        onBrokerChange(id) {
            this.selectedBroker = id;
            this.selectedServer = null;
            this.filteredServerOptions = this.serverData.filter(
                (item) => item.broker_id === id
            );

            const keys = Object.keys(this.tempData).filter(
                (item) => item === "book_regx"
            );
            keys.map((key) => {
                this.filteredOptions[key] = this.options[key].filter(
                    (item) => item.broker_id === this.selectedBroker
                );
                this.filteredOptions[key].map((item) => {
                    item.name = item.book_name;
                });
                this.filteredOptions[key].unshift({
                    name: "*",
                });
            });
        },
        onModeChange(key) {
            if (this.inputMode[key] === "regex") {
                this.tempData[key] = "";
            }
        },
        updateData() {
            this.UPDATE_LOADING(false);
            const filterKeys = Object.keys(this.inputMode);
            this.CHANGE_UPDATE_STATUS(false);
            this.CHANGE_ERROR_STATUS(false);
            this.selectedBroker = this.originalData?.broker_id;
            this.filteredServerOptions = this.serverData.filter(
                (item) => item.broker_id === this.selectedBroker
            );
            this.selectedServer = this.originalData?.server_id;
            this.filteredHeader.map((item) => {
                if (filterKeys.includes(item.value)) {
                    let plainOrRegex = "plain";
                    if (
                        this.originalData[item.value].includes("regex[") &&
                        this.originalData[item.value].includes("]") &&
                        this.originalData[item.value][
                            this.originalData[item.value].length - 1
                        ] === "]"
                    ) {
                        this.originalData[item.value][-1] === "]";
                        plainOrRegex = "regex";
                    }
                    this.inputMode[item.value] = plainOrRegex;

                    if (plainOrRegex == "plain") {
                        this.tempData[item.value] =
                            this.originalData[item.value];
                    } else {
                        this.tempData[item.value] = this.originalData[
                            item.value
                        ]
                            .replace("regex[", "")
                            .slice(0, -1);
                    }
                } else if (item.value === "login_list") {
                    const obj = JSON.parse(this.originalData[item.value]).map(
                        String
                    );
                    this.tempData[item.value] = obj;
                } else if (item.value === "book_filter" ) {
                    if(this.originalData[item.value]){
                        const obj = this.originalData[item.value].split(';')
                        this.tempData[item.value] = obj;
                    }
                    else{
                        this.tempData[item.value] = null;
                    }
                }else {
                    this.tempData[item.value] = this.originalData[item.value];
                }
            });
        },
        submit() {
            this.CHANGE_ERROR_STATUS(false);
            let valid = true;
            for (const key of Object.keys(this.tempData)) {
                if (key === "enable_notification") {
                    continue;
                }
                if (key === "book_filter") {
                    continue;
                }
                // if (key === "login_list") {
                //     this.tempData[key] = JSON.stringify(
                //         this.tempData[key].map(Number)
                //     );
                // }
                valid = valid && !!this.tempData[key];
                if (valid) {
                    continue;
                } else {
                    break;
                }
            }
            if (valid == false) {
                this.snackBarDanger(
                    "Please complete the form before submission."
                );
            } else {
                if (this.type == "Tick") {
                    this.updateConfigDataAction({
                        paramList: [{ ...this.tempData }],
                        type: this.type,
                    });
                } else {
                    this.tempData["broker_id"] = this.selectedBroker;
                    const filterKeys = Object.keys(this.inputMode);
                    Object.keys(this.tempData).map((key) => {
                        if (
                            filterKeys.includes(key) &&
                            this.inputMode[key] === "regex"
                        ) {
                            this.tempData[key] =
                                "regex[" + this.tempData[key] + "]";
                        }
                    });

                    if (this.isEditing) {
                        this.updateConfigDataAction({
                            ...this.tempData,
                            type: this.type,
                        });
                    } else {
                        this.createConfigDataAction({
                            ...this.tempData,
                            type: this.type,
                        });
                    }
                }
            }
        },
        onCancel() {
            this.$refs.configForm.reset();
            this.dialog.visible = false;
        },
        preCreationProcess() {
            this.UPDATE_LOADING(false);
            this.$refs.configForm?.reset();
            this.tempData = {};
            this.selectedBroker = null;
            this.selectedServer = null;
            this.filteredHeader.map(({ value }) => {
                if (value === "enable_notification") {
                    this.tempData[value] = false;
                } else {
                    this.tempData[value] = null;
                }
            });
            this.filteredServerOptions = [];
            Object.keys(this.inputMode).forEach((key) => {
                this.inputMode[key] = "plain";
            });
            this.CHANGE_UPDATE_STATUS(false);
            this.CHANGE_ERROR_STATUS(false);
            this.onBrokerChange(this.brokerData[0]?.id || 1);
        },
    },
};
</script>

<style scoped>
.col-4,
.col-8 {
    padding-top: 0;
    padding-bottom: 0;
}
.v-text-field {
    padding-top: 0px;
    margin-top: 0px;
}
</style>
