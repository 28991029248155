<template>
    <div class="home">
        <v-card>
            <Breadcrumbs />
            <v-container fluid>
                <v-row>
                    <v-col
                        lg="12"
                        md="12"
                        xs="12"
                        cols="12"
                        v-show="vShowList.includes('alertConfig')"
                    >
                        <v-expansion-panels
                            multiple
                            v-model="selectedExpansionAlert"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header
                                    style="padding: 0px 26px; min-height: 30px"
                                    >Alert Config</v-expansion-panel-header
                                >
                                <v-expansion-panel-content>
                                    <Alert />
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                    <v-col
                        lg="12"
                        md="12"
                        xs="12"
                        cols="12"
                        v-show="vShowList.includes('pkTaker')"
                    >
                        <v-expansion-panels
                            multiple
                            v-model="selectedExpansionChannel"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header
                                    style="padding: 0px 26px; min-height: 30px"
                                    >PK Taker Symbol Price
                                    Channel</v-expansion-panel-header
                                >
                                <v-expansion-panel-content>
                                    <TakerSymbolPriceChannel />
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                    <v-col
                        lg="12"
                        md="12"
                        xs="12"
                        cols="12"
                        v-show="vShowList.includes('brokerConfig')"
                    >
                        <v-expansion-panels
                            multiple
                            v-model="selectedExpansionBroker"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header
                                    style="padding: 0px 26px; min-height: 30px"
                                    >Broker Config</v-expansion-panel-header
                                >
                                <v-expansion-panel-content>
                                    <Broker />
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                    <v-col
                        lg="12"
                        md="12"
                        xs="12"
                        cols="12"
                        v-show="vShowList.includes('serverConfig')"
                    >
                        <v-expansion-panels
                            multiple
                            v-model="selectedExpansionServer"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header
                                    style="padding: 0px 26px; min-height: 30px"
                                    >Server Config</v-expansion-panel-header
                                >
                                <v-expansion-panel-content>
                                    <Server />
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                    <v-col
                        lg="12"
                        md="12"
                        xs="12"
                        cols="12"
                        v-show="vShowList.includes('bookConfig')"
                    >
                        <v-expansion-panels
                            multiple
                            v-model="selectedExpansionBook"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header
                                    style="padding: 0px 26px; min-height: 30px"
                                    >Books Config</v-expansion-panel-header
                                >
                                <v-expansion-panel-content>
                                    <Book />
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                    <v-col
                        lg="12"
                        md="12"
                        xs="12"
                        cols="12"
                        v-show="vShowList.includes('routingConfig')"
                    >
                        <v-expansion-panels
                            multiple
                            v-model="selectedExpansionRouting"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header
                                    style="padding: 0px 26px; min-height: 30px"
                                    >Routing Config</v-expansion-panel-header
                                >
                                <v-expansion-panel-content>
                                    <Routing />
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                    <v-col
                        lg="12"
                        md="12"
                        xs="12"
                        cols="12"
                        v-show="vShowList.includes('copyTradeConfig')"
                    >
                        <v-expansion-panels
                            multiple
                            v-model="selectedExpansionCopyTrade"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header
                                    style="padding: 0px 26px; min-height: 30px"
                                    >Copy Trade Config</v-expansion-panel-header
                                >
                                <v-expansion-panel-content>
                                    <CopyTrade />
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                    <v-col
                        lg="12"
                        md="12"
                        xs="12"
                        cols="12"
                        v-show="vShowList.includes('lpConfig')"
                    >
                        <v-expansion-panels
                            multiple
                            v-model="selectedExpansionLP"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header
                                    style="padding: 0px 26px; min-height: 30px"
                                    >LP Config</v-expansion-panel-header
                                >
                                <v-expansion-panel-content>
                                    <LP />
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                    <v-col
                        lg="12"
                        md="12"
                        xs="12"
                        cols="12"
                        v-show="vShowList.includes('notificationConfig')"
                    >
                        <v-expansion-panels
                            multiple
                            v-model="selectedExpansionNotification"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header
                                    style="padding: 0px 26px; min-height: 30px"
                                    >Notification Config</v-expansion-panel-header
                                >
                                <v-expansion-panel-content>
                                    <Notification />
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </div>
</template>
<script>
import Book from "./components/ComponentBook";
import Routing from "./components/ComponentRouting";
import Broker from "./components/ComponentBroker";
import Server from "./components/ComponentServer";
import Breadcrumbs from "@components/Breadcrumbs";
import Alert from "./components/ComponentAlert";
import Notification from "./components/ComponentNotification";
import CopyTrade from "./components/ComponentCopyTrade";
import LP from "./components/ComponentLP";
import TakerSymbolPriceChannel from "./components/TakerSymbolPriceChannel/index";
import { permission } from "@components/mixins/permission";
import { mapActions, mapState } from "vuex";

export default {
    mixins: [permission],
    components: {
        Breadcrumbs,
        Broker,
        Server,
        Book,
        Routing,
        Alert,
        TakerSymbolPriceChannel,
        CopyTrade,
        LP,
        Notification
    },
    data() {
        return {
            vShowList: [],

            selectedExpansionBroker: [],
            selectedExpansionServer: [],
            selectedExpansionBook: [],
            selectedExpansionRouting: [],
            selectedExpansionAlert: [],
            selectedExpansionChannel: [],
            selectedExpansionCopyTrade: [],
            selectedExpansionLP: [],
            selectedExpansionNotification:[]
        };
    },
    methods: {
        ...mapActions("SettingsBroker", ["getBrokerAction"]),
        ...mapActions("SettingsBooks", ["getBooksAction"]),
        ...mapActions("SettingsServer", ["getServerAction"]),
        ...mapActions("Config", ["getOptionsAction"]),
        ...mapActions("LP", ["getLPInfoAction"]),
        ...mapActions("SettingsCopyTrade", ["getCopyTradeConfigAction"]),
    },

    created() {
        this.vShowList =
            JSON.parse(localStorage.getItem("permission"))?.frontPermission
                ?.settings?.child || [];
    },

    computed: {
        ...mapState("SettingsBroker", ["brokerData"]),
        ...mapState("SettingsServer", ["serverData"]),
        combined() {
            return this.serverData && this.brokerData;
        },
    },
    watch: {
        combined() {
            if (this.serverData.length !== 0 && this.brokerData.length !== 0) {
                if (!!this.$route.query.selected_type) {
                    this.selectedExpansionAlert = [0];
                    const type = this.$route.query.selected_type;
                    setTimeout(() => {
                        document.getElementById(type).click();
                    }, 50);
                } else if (
                    !!this.$route.query.editing_target &&
                    this.$route.query.editing_target === "copy_trade"
                ) {
                    this.selectedExpansionCopyTrade = [0];
                }
            }
        },
    },
    mounted() {
        this.getOptionsAction();
        this.getBrokerAction();
        this.getServerAction();
        this.getBooksAction();
        this.getLPInfoAction();
        this.getCopyTradeConfigAction();
        if (
            !!this.$route.query.editing_target &&
            this.$route.query.editing_target === "lp"
        ) {
            this.selectedExpansionLP = [0];
        }
    },
};
</script>

<style scoped>
div >>> .v-data-table--fixed-header > .v-data-table__wrapper {
    overflow-y: hidden;
}
</style>
